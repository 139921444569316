import logo from './logo.svg';
import styles from './App.css';
import React, {useState, useEffect } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import * as solanaWeb3 from '@solana/web3.js';
import parse from 'html-react-parser';
import background from "./bg.png";
import ProgressBar from 'react-bootstrap/Button';
import reactDom from 'react-dom';




const unityContext = new UnityContext({
  loaderUrl: "/buildTest/skeletonTest.loader.js",
  dataUrl: "/buildTest/skeletonTest.data",
  frameworkUrl: "/buildTest/skeletonTest.framework.js",
  codeUrl: "/buildTest/skeletonTest.wasm",
});





function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [progression, setProgression] = useState(0);
  const [provider, setProvider] = useState("");
  


  const getProvider = async () => {
    if ("solana" in window) {
      
      await window.solana.connect(); // opens wallet to connect to
  
      const provider = window.solana;
      if (provider.isPhantom) {
        console.log("Is Phantom installed?  ", provider.isPhantom);
        return provider;
      }
    } else {
      window.alert('Install https://www.phantom.app/');
    }
  };
  
  var publicKey;
  
  window.onload = () => {
  
    getProvider().then(provider => {
      console.log('key',
      
      
      provider.publicKey.toString());
      publicKey = provider.publicKey.toString();
      setProvider(provider.publicKey.toString());
    })
    .catch(function(error){
      console.log(error)
    });
  
  }
  


  useEffect( () => {
   setProvider(publicKey);
  }, []);
  useEffect( () => {
    setProvider(publicKey);
   }, []);


   useEffect(function () {

    
    unityContext.on("GetWallet", function () {

         
     
      if (window.solana.isConnected)
      {
        console.log(window.solana.publicKey.toString());
      
      console.log(provider.publicKey);
      unityContext.send("Wallet", "SetWallet", window.solana.publicKey.toString());

      }
      else
      {
        window.alert("Pleas Connect your Wallet or play like a guest");
      }
      

      
     
    });
  }, []);


  useEffect(function () {
    unityContext.on("progress", function (progression) {
      setProgression(progression);
    });
  }, []);


  useEffect(function () {
    unityContext.on("loaded", function () {
      setIsLoaded(true);
    });
  }, []);
 



  return (

  <div style = {{
    visibility: isLoaded ? "hidden" : "visible",
    backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      height: "100%",
      width: "100%",
      color: "#f5f5f5",
    position: 'absolute',
    left: 0,
    top: 0,

    
  }}>
   <div  >
  <ProgressBar variant="success" now={progression * 100}
  style={{ visibility: isLoaded ? "hidden" : "visible",
  height: "10px",
  width: "100%",
  position: "absolute",
  bottom: 0,
  color: "red",
  }}
  
  />
  
    </div>
    
   <p
    style={{ visibility: isLoaded ? "hidden" : "visible",
    height: "30px",
    width: "100%",
    position: "absolute",
    textAlign: 'center',
    bottom: "100px",
    fontSize: "2em",
    fontColor: "red",

    
 
    }}>Loading...</p>
   
   <div>
   <Unity
      style={{ visibility: isLoaded ? "visible" : "hidden",
        height: "100%",
        width: "100%",
        background: "grey",
        position: 'absolute',
        left: 0,
        top: 0,
        overflow: "hidden",
     
    }}
      unityContext={unityContext}
    />
    </div>
    </div>
  );
}

export default App;